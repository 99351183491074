import './App.css';
import './styling/admin.css'
import './styling/global.css'
import Dashboard from './Client/Backend/pages/Dashboard';
import Deposit from './Client/Backend/pages/Deposit';
import Home from './Client/Backend/pages/Home';
import Investment from './Client/Backend/pages/Investment-plans';
import Withdrawl from './Client/Backend/pages/Withdrawl';
import Transaction from './Client/Backend/pages/Transaction';
// import SideNav from './Client/Backend/components/SideNav';
// import OuterNav from './Client/Components/OuterNav';
import About from './Client/Pages/About';
import Contact from './Client/Pages/Contact';
import Investments from './Client/Pages/Investments';
import Login from './Client/Pages/Login';
import Signup from './Client/Pages/Signup';
import Startpage from './Client/Startpage';
import Admin from './Client/Admin/Admin';
import Receipt from './Client/Backend/pages/Receipt'
import { Route, Routes } from 'react-router-dom';
import './styling/transaction-list.css'
import './styling/pop.css'
import PostWithdrawal from './Client/Backend/pages/PostWithdrawal';
import Email from './Client/Components/Email';
import AdminVerify from './Client/Admin/AdminVerify';
import DepositHub from './Client/Admin/DepositHub';

function App() {
  return (
    <div className="App">
      {/* <OuterNav/> */}
      <Routes> 
        <Route path='/' element={<Startpage/>}/>
        <Route path='/About-us' element={<About/>}/>
        <Route path='/investment-plans' element={<Investments/>}/>
        <Route path='/contact-us' element={<Contact/>}/>
        <Route path='/sign-up' element={<Signup/>}/>
        <Route path='/admin' element={<Admin/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/Dashboard' element={<Login/>}/>
        <Route path='/userdashboard' element={<Home/>}/>
        <Route path='/transaction' element={<Transaction/>}/>
        <Route path='/investment/Invest-now' element={<Investment/>}/>
        <Route path='/deposit-funds' element={<Deposit/>}/>
        <Route path='/postwithdrawal' element={<PostWithdrawal/>}/>
        <Route path='./Client/Backend/pages/home' element={<h1>Dashboard</h1>}/>
        <Route path='/withdraw-funds' element={<Withdrawl/>}/>
        <Route path='/receipt' element={<Receipt/>}/>
        <Route path='/postwithdrawal' element={<PostWithdrawal/>}/>
        <Route path='/email' element={<Email/>}/>
        <Route path='/adminverify' element = {<AdminVerify />} />
        <Route path='/deposithub' element = {<DepositHub />} />
        {/* <Route path='/sideNav' element={<SideNav/>}/> */}
      </Routes>

    </div>
  );
}

export default App;

import { useState } from "react"
import { useNavigate } from "react-router-dom"


const AdminVerify = () => {

    const [phrase, setPhrase] = useState('')
    const [message, setMessage] = useState('Incorrect phrase!')
    const navigate = useNavigate()
    const [show, setShow] = useState(false)

    const secret = 'secret'


    const handleVerify = (e) => {
        e.preventDefault()
        if(secret == phrase)   {
            localStorage.setItem('admin_phrase', phrase)

            navigate('../admin')
        }
        else if(phrase === '')   {
            setMessage('Enter phrase!')
            setShow(true)
        }

        else setShow(true)
    }

    return (
        <div className="admin-verify-body">
            <div>
                <form>
                    <h1 className = {'phrase-label'}>
                        Enter secret phrase
                    </h1>
                    <input
                        className="phrase-input"
                        type = {'input'}
                        value = {phrase}
                        onChange={(e) => setPhrase(e.target.value)}
                    >
                    </input>
                    <button
                        onClick={handleVerify}
                        className="admin-verify-button">
                        Enter Admin
                    </button>
                </form>
                <p className = {show ? 'message-show' : 'message-hide'}>
                    {message}
                </p>
            </div>
        </div>
    )
}

export default AdminVerify
import MiniDrawer from '../components/drawer'
import PersistentDrawerLef from '../components/phone'
import '../../../styling/receipt.css'
import { useNavigate } from 'react-router-dom';
import { use } from 'i18next';
import { useState } from 'react';
import Services from '../../services/WebService'



const Receipt = () => {

    const navigate = useNavigate()

    const username = localStorage.getItem('name')
    const [hash, setHash] = useState(null)
    const [amountUSD, setAmountUSD] = useState(null)
    const [ss, setSs] = useState(null)
    const date = new Date()
    const cryptoType = 'BTC'


    const amount = localStorage.getItem('amount')
    const method = localStorage.getItem('method')
    const charge = 0.05
    const payable = Number(amount) + Number(charge * amount)


    const handleDeposit = (e) => {
        e.preventDefault()

        if(hash === null)   {
            window.alert('enter transaction hash')
        }   else if(amountUSD === null) {
            window.alert('enter amount in USD')
        }   else if (ss === null)  {
            window.alert('submit payment screenshot')
        }
        

        else    {
        const payload = {
            username, hash, amountUSD, cryptoType, date
        }
        console.log(username)

        Services.addDepositToHub(username, payload)

        setTimeout(() => {
            navigate('../userdashboard')
        }, 2000)
    }
    }


    return(
       <div>
        <MiniDrawer>
            <div className = 'receipt-wrapper'>
            <form className = 'receipt-form'>
                <label>Transaction Hash</label>
                <input
                onChange = {(e) => setHash(e.target.value)}
                    type = ''>
                </input>
                <label>Amount Sent in Dollars</label>
                <input
                onChange = {(e) => setAmountUSD(e.target.value)}
                type = 'number'></input>
                <label>Payment Screenshot</label>
                <input
                    value = {ss}
                    onChange = {(e) => setSs(e.target.value)}
                    type = 'file'>
                    </input>
                <button
                onClick={handleDeposit}
                class = 'receipt-button'
                type = 'submit'>Deposit Funds</button>
            </form>
            <div className = {'payment-wrapper'}>
                <h1>Payment Information</h1>
                <div className='payment-information-wrapper'>
                <div className = {'payment-information'}>
                            {['Gateway Name    ',
                            'Amount    ',
                            'Charge    ',
                            'Conversion Rate    ',
                            'Total payable amount    ']
                            .map(item => <p>{item}</p>
                            )}
                        </div>
                    <div className = {'payment-information conv-rate'}>
                        {[method,
                        amount,
                        `${charge}%`,
                        'Conversion Rate',
                        `${payable}USD`]
                        .map(item => <p>{'\t'}{item}</p>
                        )}
                    </div>
                        </div>
            </div>
            </div>
        </MiniDrawer>
        <PersistentDrawerLef>
            <div className = 'receipt-wrapper-mobile'>
            <div className = {'payment-wrapper-mobile'}>
                <h1>Payment Information</h1>
                <div className='payment-information-wrapper-mobile'>
                <div className = {'payment-information'}>
                            {['Gateway Name    ',
                            'Amount    ',
                            'Charge    ',
                            'Conversion Rate    ',
                            'Total payable amount    ']
                            .map(item => <p>{item}</p>
                            )}
                        </div>
                    <div className = {'payment-information'}>
                        {[method,
                        amount,
                        `${charge}%`,
                        'Conversion Rate',
                        ` ${payable}USD`]
                        .map(item => <p>{'\t'}{item}</p>
                        )}
                    </div>
                        </div>
            </div>
            <form className = 'receipt-form'>
                <label>Transaction Hash</label>
                <input
                    type = 'text'></input>
                <label>Amount Sent in Dollars</label>
                <input type = 'text'></input>
                <label>Payment Screenshot</label>
                <input type = 'file'></input>
                <button
                onClick={handleDeposit}
                class = 'receipt-button'
                type = 'submit'>Deposit Funds</button>
            </form>
            </div>
        </PersistentDrawerLef>
       </div>
    )
}

export default Receipt
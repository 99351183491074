import UserIcon from './components/UserIcon'
import TopBar from './components/TopBar'
import Main from './components/Main'
import Services from '../../Client/services/WebService'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'



const Admin = () => {

    const [users, setUsers] = useState([])
    const [item, setItem] = useState('users')
    const navigate = useNavigate()
    const [userClass, setUserClass] = useState('admin-topbar-p')
    const [depositClass, setDepositClass] = useState('p-deselected')

    const handleClick = (e, value) => {
        setItem(value)
        console.log('value is', value)
    }

    const phrase = localStorage.getItem('admin_phrase')


    useEffect(() => {
        if(phrase === null)  {
            navigate('../adminverify')
            return;
        }

        Services.getAll()
            .then(data => setUsers(data.data))
            .catch(e => console.log(e.message))
    })

    return(
        <div style={{"backgroundColor": "white"}}>
            <TopBar handleDeposit = {(e, value) => {
                handleClick(e, 'deposits')
                setUserClass('p-deselected')
                setDepositClass('admin-topbar-p')
            }}
                    handleUser = {(e, value) => {
                        handleClick(e, 'users')
                        setDepositClass('p-deselected')
                        setUserClass('admin-topbar-p')
                    }}
                    userClass = {userClass}
                    depositClass = {depositClass}
                    />
            <Main menuItem = {item}/>
        </div>
    )
}

export default Admin
import { useEffect, useState } from "react"
import { AnimatePresence, motion } from 'framer-motion'
import { myArray } from '../../name'



const Pop = () => {

    const [show, setShow] = useState(false)
    const [name, setName] = useState('Mark Dreur')
    const [amount, setAmount] = useState(5000)

    const handleName = () => {
        let arrayNumber = Math.floor(Math.random() * myArray.length)
        setName(myArray[arrayNumber])

        let USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        let amount = 0
        amount = parseFloat(
                Math.random()
        ).toFixed(3) * 1000
        setAmount(USDollar.format(amount))

        
    }

    const handleShow = () => {

        const timer = setTimeout(() => {
            setShow(true)
            console.log(show)
            const hide = setTimeout(() => {
                setShow(false)
                console.log(show)
            }, 30000)

            return () => clearTimeout(hide)
        }, 20000)

        return () => clearTimeout(timer)
    }

    useEffect(() => {
        handleShow()
        handleName()

    }, [show])

    return(
        <div className = {'pop-container'}>
            <AnimatePresence>
            {show ? <motion.div
            initial = {{opacity: 0, x: 100}}
            animate = {{opacity: 1, x: 0}}
            exit = {{opacity: 0, x:  100}}
            transition = {{duration: 1, ease: 'easeInOut', type: 'spring'}}
            className="pop">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="orange" className="w-10 h-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
                 </svg>
            </div>

            <div className="pop-text">
                <h2 className="pop-title">
                    Alert
                </h2>
                <p className="pop-body">
                    {name} just got a profit of {amount} USD on his investments
                </p>
                <p className="pop-time">
                    6 seconds ago
                </p> 
            </div>
        </motion.div> : null}
        </AnimatePresence>
        </div>
    )
}

export default Pop
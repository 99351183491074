import React from 'react'
import MiniDrawer from '../components/drawer'
import PersistentDrawerLef from '../components/phone';
import bitcoin from '../../image/bitcoin.png'
import ethereum from '../../image/ethereum.jpg'
import { useNavigate } from 'react-router-dom';



const Deposit = () => {
  
const navigate = useNavigate()

const handlePay = (method) => {
  localStorage.setItem('amount', window.prompt('Enter the deposit amount'))
  localStorage.setItem('method', method)
  navigate('../receipt')

  
  console.log('deposit')
}

  return (
    <div>
      <div className="hidden md:block">
        <MiniDrawer>
          <div className = {'bg-[#101010] deposit-background'}>
            <div className={'deposit-cards'}>
              <img alt ={'bitcoin'}
              src={bitcoin}
              className='deposit-image'
              height={500}>
              </img>
              <div
              onClick = {(methode) => handlePay('Bitcoin')}
              className = {'bg-orange-500 hover:bg-orange-600 text-l font-bold p-4 text-center'}>
                Pay With Bitcoin
              </div>
            </div>
            
            <div className={'deposit-cards'}>
            <img alt ={'bitcoin'}
              src={ethereum}
              className='deposit-image'
              height={500}>
              </img>
              <div
              onClick={(method) => handlePay('Ethereum')}
              className = {'bg-orange-500 hover:bg-orange-600 text-l font-bold p-4 text-center'}>
                Pay With Ethereum
              </div>
            </div>
            
          </div>
        </MiniDrawer>
      </div>
      <div className="block md:hidden">
        <PersistentDrawerLef>
          
          <div className = {'bg-[#101010] deposit-bg-mobile'}>
            <div className={'deposit-cards-mobile'}>
              <img alt ={'bitcoin'}
              src={bitcoin}
              className='deposit-image'
              height={500}>
              </img>
              <div
                onClick = {(methode) => handlePay('Bitcoin')}
                 className = {'bg-orange-500 hover:bg-orange-600 text-l font-bold p-4 text-center'}>
                Pay With Bitcoin
              </div>
            </div>
            
            <div className={'deposit-cards-mobile'}>
            <img alt ={'bitcoin'}
              src={ethereum}
              className='deposit-image'
              height={500}>
              </img>
              <div
                onClick={(method) => handlePay('Ethereum')}
                className = {'bg-orange-500 hover:bg-orange-600 text-l font-bold p-4 text-center'}>
                Pay With Ethereum
              </div>
            </div>
          </div>
        </PersistentDrawerLef>
      </div>
    </div>
  );
}

export default Deposit
import { useEffect, useState } from 'react'
import Services from '../../../Client/services/WebService'


const Table = () => {
    
    //declaring states
    const [users, setUsers] = useState([])
    const [balance, setBalance] = useState(0)

    //function to feth users from database
    const getUsers = () => {
        Services.getAll()
                .then(data => setUsers(data.data))
                .catch(e => console.log(e.message))
    }
    
    useEffect(() => {
            getUsers()
    })
    

    //function to handle deleting  user
    const handleDelete = (e, index) => {
        const user = users[index].username
        console.log(index)

        if(window.confirm(`Delete ${user} from FinvestHub`))
        {
            console.log(`Deleting ${user}`)
            Services.deleteUser(user)
            getUsers()
        }
        
    }

    //function to handle editing balance
    const editBalance = (e, index) => {
        console.log(index)
        const user = users[index].username
        const amount = {
            amount: window.prompt('Enter new balance')
        }

        Services.editBalance(user, amount)
            .then(data => console.log(data.data))
            .catch(err => console.log(err.message))

    }

    return (
        <div>
             {users.length > 0 ?<table>
                <tr>
                    <th>S/No</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Balance</th>
                    <th>Joined</th>
                </tr>

                {  users.map((user, index) => 
                <tr key = {index}>
                    <div>{index + 1}</div>
                    <td>{user.fullname}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td >{user.balance}</td>
                    <td>{user.createdAt}</td>
                    <div className='admin-functions'>
                        {/* <td onClick = {(e) => editBalance(e, index)} className='edit-user'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="white" className="w-6 h-6"> <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" /> </svg>
                        </td> */}
                        <td onClick = {(e) => handleDelete(e, index)} className='delete-user-container'>
                             <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="white" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" /></svg>
                        </td>
                        <td onClick = {(e) => editBalance(e, index)} className='deduct-balance'>
                            -
                        </td>
                        <td onClick = {(e) => editBalance(e, index)} className='add-user'>
                            +
                        </td>
                    </div>

                </tr>
                )}
            </table> : <div>Loading Users... typically takes 5 seconds</div>}
        </div>
    )
}

export default Table
import React, { useState } from 'react'
import OuterNav from '../Components/OuterNav'
import Footer from '../Components/Footer';
import { grey, green } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import { Link } from 'react-router-dom';
import axios from 'axios'
import userService from '../services/WebService'
import { useNavigate } from 'react-router-dom';
import e from 'cors';


const Signup = () => {
  const navigate = useNavigate()

//declaring form variables
const [fullname, setFullName] = useState('')
const [email, setEmail] = useState('')
const [username, setUsername] = useState('')
const [password, setPassword] = useState('')
const [confirm, setconfirm] = useState('')
const [checkbox, setCheckbox] = useState(false)
const buttonColor = checkbox ? 'w-full outline-none mt-1 bg-[#FFA500] px-2 h-[2rem]'
                             : 'w-full outline-none mt-1 bg-[#333333] px-2 h-[2rem]'
const [message, setMessage] = useState(null)
const [pswdStrength, setPswdStrength] = useState('weak')
const [pswdColor, setPswdColor] = useState('text-red-500')
const [loader, setLoader] = useState('loader-hidden')

//function to handle messages sent to the user
const handleMessage = (time, message) => {
  setTimeout(() => {
    setMessage(null)
  }, time)

  setMessage(message)
}

//function to handle inputs in form fields
const handleInput = (e, value, setValue) => {
  e.preventDefault()
  setValue(e.target.value)
  handlePasswordStrength()
  console.log(password.length)
}

//function to handle checkbox
const handlecheckbox = () => {
  setCheckbox(!checkbox)
  console.log(checkbox)
}

//to handle password strength

const handlePasswordStrength = () => {
  if(password.length > 8 ) {
    setPswdStrength('good')
    setPswdColor('text-green-500')
  }
  else if(password.length > 5)  {
    setPswdStrength('medium')
    setPswdColor('text-yellow-500')
  }
  else{
  setPswdColor('text-red-500')
  setPswdStrength('weak')
  }
}

//function to handleSignUp
const handleSignUp = async(e) => {
  e.preventDefault()
  setLoader('loader')

  const userData = {
    fullname: fullname,
    email: email.toLowerCase(),
    username: username.toLowerCase(),
    password: password,
    confirm: confirm,
    deposit: 0,
    withdrawal: 0
  }

  if(fullname === '' || email === '' ||
     username === '' || password === '' ||
     confirm === '')  {
      handleMessage(5000, 'Complete all fields')
     }
    else if (password !== confirm)  {
     handleMessage(5000, 'passwords do not match')
    }
    else if (pswdStrength === 'weak') {
      handleMessage(5000, 'Password is weak')
    }
    else if (!email.includes('@') || !email.includes('.'))  {
      handleMessage(5000, 'Invalid email entered')
    }

    else {
      console.log('attempting sign up')
      await userService.getSingleUser(userData.username.toLowerCase())
        .then(async data => {
          if(data.data === null)  {
            console.log('data is null')
            await userService.addUser(userData)
              .then(() => 
                  {
                    console.log('requesting sign up')
                    navigate('../login')
               })
            .catch(err => console.log(err.message))
          console.log(data.data)
          }
          else{
            handleMessage(5000, 'User already exist')
          }
          console.log(data)
        }
      //   //     userService.addUser(userData)
      //   //     .then(() => 
      //   //         {
      //     //           console.log('requesting sign up')
      //     //           // localStorage.setItem('name', username)
      //     //           // navigate('../dashboard')
      //     //        })
      //     //     .catch(err => console.log(err.message))
      //     //   console.log(data.data)
      //     // })
      //     // .catch(err => {
      //       //   console.log(err)
            )
          }
          
          console.log('sign up data is', userData)
          setLoader('loader-hidden')

}
    const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <div className="bg-black text-white">
          <div className = {loader}></div>
      <OuterNav />
      <div className="pt-24 w-full px-5 md:px-10 text-white">
        <div className="pb-10 pt-5 hfhfhhf md:flex justify-center">
          {/* <img
            className="w-[40%] md:block hidden pt-20"
            src="https://i.pinimg.com/564x/7f/ce/0f/7fce0f11acea7f6e161c3022580baf56.jpg"
            alt=""
          /> */}
          <section className="md:w-[20rem] p-3 bg-blac border shadow-inner shadow-[#FFA500]">
            <div className="flex justify-center items-center mt-3">
              <img
                src="../image/finvest-logo-1.png"
                alt=""
                className="w-[30%]"
              />
            </div>
            <p className="text-sm font-bold text-center mt-3">
              CREATE AN ACCOUNT
            </p>
          <form>
            <div className="mt-3 text-sm font-semibold">
              <label htmlFor="">Full Name</label>
              <br />
              <input
                onChange = {(e, value, setValue) => handleInput(e, fullname, setFullName)}
                value = { fullname }
                type="text"
                className="w-full mb-3 border  outline-none bg-transparent px-2 h-[2rem]"
              />

              <label htmlFor="">Email</label>
              <br />
              <input
                type="email"
                required = {true}
                className="w-full mb-3 border outline-none bg-transparent px-2 h-[2rem]"
                onChange = {(e, value, setValue) => handleInput(e, email, setEmail)}
                value = { email }
              />

              <label htmlFor="">Username</label>
              <br />
              <input
                type="text"
                className="w-full mb-3 border outline-none bg-transparent px-2 h-[2rem]"
                onChange = {(e, value, setValue) => handleInput(e, username, setUsername)}
                value = { username }
              />

              <label htmlFor="">Password</label>
              <br />
              <input
                type="password"
                className="w-full mb-3 border outline-none bg-transparent px-2 h-[2rem]"
                onChange = {(e, value, setValue) => handleInput(e, password, setPassword)}
                value = { password }
              />

              <label htmlFor="">Comfirm Password</label>
              <br />
              <input
                type="password"
                className="w-full mb-1 border outline-none bg-transparent px-2 h-[2rem]"
                onChange = {(e, value, setValue) => handleInput(e, confirm, setconfirm)}
                value = { confirm }
              />
               {password ? <p className = {`${pswdColor}`}>
                  {pswdStrength}
               </p> : null}
              <p className="text-xs">
                <span className="bg-whit w-fit text-white">
                  <Checkbox
                    value =  {checkbox}
                    onChange = {handlecheckbox}
                    {...label}
                    sx={{
                      color: grey[50],
                      "&.Mui-checked": {
                        color: green[500],
                      },
                    }}
                    size="small"
                  />
                </span>
                I Agree To The Terms and Conditions.
              </p>
              <button
              type = "submit"
                disabled = {!checkbox}
                onClick = {handleSignUp}
                className= {buttonColor}>
                Sign Up
              </button>
              {message && <p
                className='text-red-500 text-l my-4'>
                {message}
                </p>}
              <p className="text-xs mt-1">
                Already have an account with us?{" "}
                <Link to="/login">
                  <span className="text-[#FFA500]">Signin here</span>
                </Link>
              </p>
            </div>
          </form>
          </section>
          <div className="md:w-[60%] md:mt-0 mt-10 md:px-10">
            <p className="md:text-xl md:pt-3  md:pb-5 uppercase font-bold ">
              Terms <span className="text-[#F9A403]">and</span> Conditions.
            </p>

            <div className="">
              <p className="text-sm font-semibold text-[#F9A403]">
                Account Registration
              </p>
              <p className="text-xs mt-2">
                In order to access certain features of the Website, you may be
                required to create an account. You agree to provide accurate,
                complete, and up-to-date information during the registration
                process. You are responsible for maintaining the confidentiality
                of your account information, including your password, and you
                agree to accept responsibility for all activities that occur
                under your account.
              </p>

              <p className="text-sm mt-5 font-semibold text-[#F9A403]">
                Prohibited Activities
              </p>
              <p className="text-sm font-semibold">
                When using the Website, you agree not to engage in any of the
                following activities
              </p>
              <ol className="mt-2 text-xs list-decimal pl-5">
                <li>Violating any applicable laws or regulations.</li>
                <li>
                  Impersonating any person or entity, or falsely representing
                  your affiliation with any person or entity.
                </li>
                <li>
                  Accessing or using the accounts of other users without
                  authorization.
                </li>
                <li>
                  Transmitting any viruses, worms, malware, or any other harmful
                  code.
                </li>
                <li>
                  Interfering with or disrupting the security, integrity, or
                  performance of the Website.
                </li>
                <li>
                  Collecting or harvesting any personally identifiable
                  information from the Website without permission.
                </li>
                <li>
                  Engaging in any activity that places an excessive load on the
                  infrastructure of the Website.
                </li>
              </ol>

              <p className="text-sm mt-5 font-semibold text-[#F9A403]">
                Privacy
              </p>
              <p className="text-xs mt-2">
                Wealth Frontier values your privacy and takes the protection of
                your personal information seriously. Our collection, use, and
                disclosure of your personal information are governed by our
                Privacy Policy. By using the Website, you consent to the
                collection, use, and disclosure of your personal information as
                described in our Privacy Policy.
              </p>

              <p className="text-xs mt-5 text-[#F9A403]">
                If you have any questions or concerns about these Terms, please
                contact us at{" "}
                <a href="">
                  <span className="underline">ADMIN@FINVESTHUB.CO</span>
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Signup
import React, { useEffect, useState } from 'react'
import MiniDrawer from '../components/drawer'
import PersistentDrawerLef from '../components/phone';
import bitcoin from '../../image/bitcoin.png'
import ethereum from '../../image/ethereum.jpg'
import Services from '../../services/WebService'

const user = localStorage.getItem('name')

const Transaction = () => {
  
  const [transactions, settransactions] = useState([])

  useEffect(() => {
    Services.getSingleUser(user)
      .then(data => {
        console.log(data.data.transactions)
        settransactions(data.data.transactions)
      })
  })

  const handleTransactions = () => {
  
  }


  return (
    <div>
      <div className="hidden md:block">
        <MiniDrawer>
          <div className = {'bg-[#101010] transaction-bg'}>
            <div className={'transaction-table-title'}>
                <div className='transaction-title-cell'>Method</div>
                <div>Date</div>
                <div>Description</div>
                <div>Amount</div>
            </div>
            {transactions.length === 0 ?
            <p className = {'text-center pt-5'}>
                No transactions at the moment
                </p>
                          : transactions.map(transaction => 
            <div className={'transaction-table'}>
                <div className='transaction-title-cell'>{transaction.method}</div>
                <div>{transaction.date}</div>
                <div>{transaction.note}</div>
                <div>{transaction.amount}</div>
            </div>
            )}
            
          </div>
        </MiniDrawer>
      </div>
      <div className="block md:hidden">
        <PersistentDrawerLef>
          <div className = {'bg-[#000000] transaction-bg-mobile'}>
            <div className={'transaction-table-title-mobile'}>
                <div className='transaction-title-cell-mobile'>Method</div>
                <div>Date</div>
                <div>Description</div>
                <div>Amount</div>
            </div>
            {transactions.length === 0 ?
            <p className = {'text-center pt-5'}>
                No transactions at the moment
                </p>
                          : transactions.map(transaction => 
            <div className={'transaction-table-mobile'}>
                <div className='transaction-title-cell'>{transaction.method}</div>
                <div>{transaction.date}</div>
                <div>{transaction.note}</div>
                <div>{transaction.amount}</div>
            </div>
            )}
          </div>
        </PersistentDrawerLef>
      </div>
    </div>
  );
}

export default Transaction
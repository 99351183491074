import { Avatar } from "@mui/material"
import UserIcon from "./UserIcon"
import { useNavigate } from "react-router-dom"

const item = localStorage.getItem('menuItem')

const TopBar = (props) => {

    const navigate = useNavigate()

    return(
        <div className="admin-topbar">
            <div className="admin-topbar-text">
                <h1>
                    Admin
                </h1>
                <p onClick = {props.handleUser}
                    className= {props.userClass}>
                   Users
                </p>
                <p onClick = {props.handleDeposit}
                    className= {props.depositClass}>
                   Deposit Hub
                </p>
            </div>
            <div>
                <UserIcon />
            </div>
        </div>
    )
}

export default TopBar
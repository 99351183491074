import '../../../styling/postwithdrawal.css'
import completed from '../../image/completed.png'
import { useNavigate } from 'react-router-dom';


const PostWithdrawal = () => {
    const navigate = useNavigate()
    const amount = localStorage.getItem('withdrawal')


    return (
        <div className='main-bg'>
            <img src={completed} className={'completed'}></img>
            <h1>Withdrawal Processing</h1>
            <p className='firstP'>Withdrawal info submitted <br></br>View history to view your order status</p>
            
            <div className='space'>$nbsp</div>

            <p>Recipient Amount</p>
            <h2>{amount}USD</h2>

            <div className='space'>$nbsp</div>

            <div className = {'info-div'}>
                <div className='text-left'>
                    <p>Transaction Method</p>
                    <p>Indicated Amount</p>
                    <p>Transaction Fee</p>
                </div>
                <div className='text-right'>
                    <p>Transaction Method</p>
                    <p>Indicated Amount</p>
                    <p>Transaction Fee</p>
                </div>
            </div>

            <div className='button-div'>
            <button
                onClick={() => navigate('../transaction')}
                className='view-history postwithdrawal-button'>View History</button>
            <button
                onClick={() => navigate('../dashboard')}
                className='view-dashboard postwithdrawal-button'>View Dashboard</button>
            </div>
        </div>
    )
}

export default PostWithdrawal
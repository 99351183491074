import Table from './Table'
import DepositHub from '../DepositHub'


const Main = (props) => {

    const item = props.menuItem

    const handleView = () => {
 
        if(item === 'users')   {
            return  <Table />
        }

        else if(item === 'deposits')    {
            return <DepositHub />
        }
    }

    return (
        <div className='admin-main'>
            {
               handleView()
            }
        </div>
    )
}

export default Main
import { Avatar } from "@mui/material"

const UserIcon = () => {

    return(
        <div className="usericon">
            <label>
                Finvest Admin
            </label>
            <div>
                <Avatar />
            </div>
        </div>
    )
}

export default UserIcon 
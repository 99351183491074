import React from 'react'
import OuterNav from '../Components/OuterNav'
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { grey } from "@mui/material/colors";
import Footer from '../Components/Footer';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Services from '../services/WebService'

const Login = () => {
       const labels = {
         weak: 'weak',
         medium: 'medium',
         strong: 'strong'
    };
    
    const navigate = useNavigate()
    const [value, setValue] = React.useState(2);
    const [show, setShow] = React.useState("");
    const [hover, setHover] = React.useState(-1);
    const [feedback, setFeedback] = useState(null)
    const [loader, setLoader] = useState('loader-hidden')

    //declaring form variables
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [alpha, setAlpha] = useState('null')
    function getLabelText(value) {
       if (labels === value) {
         setShow("Sent!! ✔");
       }
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
    }
    
 

//function to handle messages sent to the user
const handleFeedback = (time, newMessage) => {
  setTimeout(() => {
    console.log('handle message called')
    setFeedback(null)
    console.log(feedback)
  }, time)

  setFeedback(newMessage)
  console.log('message')
}


    //function to handle form field
    const handleUsername = (e, value, setValue) => {
      e.preventDefault()
      setValue(e.target.value)
      console.log(value)
    }

    //function to handle submit and signin button
    const handleSignIn = async (e) => {
      const userCred = {
        username: username,
        password: password.toLowerCase,
        date: new Date(),
      }
      setLoader('loader')

      if(username === '') {
        console.log('input username')
        handleFeedback(5000, 'Enter username')
      }
      else if (password === '') {
        console.log('input password')
        handleFeedback(5000, 'Enter password')
      } 
      else await Services.getSingleUser(username.toLowerCase())
        .then(data => {
          setAlpha(data.data)
          console.log('data is', data)
          if(!data.data)  {
            handleFeedback(5000, 'User does not exist')
          }
          else if (password !== data.data.password) {
            handleFeedback(5000, 'Wrong password')
          }
          else {
            handleFeedback(5000, 'Correct password')
            localStorage.setItem('name', username)
            navigate('../userdashboard')
          }
          console.log('alpha is ', alpha)
          console.log('user found!!')
          console.log('data.data is', data.data)
          console.log(userCred)
          
        })
        .catch(err => {
          console.log(err)
          handleFeedback(5000, 'Something went wrong')
        })
        
      setLoader('loader-hidden')
      e.preventDefault()
        
    
    }



      

  return (
    <div>
    <div className = {loader}></div>
      <div className="py-24 bg-black w-full px-5 md:px-10 text-white">
        <div className="flex flex-col items-center gap-5">
          <section className="md:w-[20rem] p-3 bg-blac border shadow-inner shadow-[#FFA500]">
            <div className="flex justify-center items-center mt-3">
              <img
                src="../image/finvest-logo-1.png"
                alt=""
                className="w-[30%]"
              />
            </div>
            <p className="text-sm font-bold text-center mt-3">
              Sign in to your account
            </p>

            <div className="mt-3 text-sm font-semibold">
              <label htmlFor="">Email or Username</label>
              <br />
              <input
                type="text"
                className="w-full mb-3 border outline-none bg-transparent px-2 h-[2rem]"
                onChange = {(e, value, setValue) => handleUsername(e, username, setUsername)}
                value = {username}
              />

              <label htmlFor="">Password</label>
              <br />
              <input
                type="password"
                className="w-full mb-3 border outline-none bg-transparent px-2 h-[2rem]"
                onChange = {(e, value, setValue) => handleUsername(e, password, setPassword)}
                value = {password}
              />


              <button className="w-full outline-none mt-1 bg-[#FFA500] px-2 h-[2rem] hover:bg-[#E86E0A]"
                      onClick = {handleSignIn}
                      >
                Sign in
              </button>
              <p
                className='text-red-500 text-l my-4'> 
              {feedback}
              </p>
              <p className="text-xs mt-1">
                Dont have an account with us?{" "}
                <Link to="/sign-up">
                  <span className="text-[#FFA500]"
                        >Signup here</span>
                </Link>
              </p>
            </div>
          </section>

          <section className="md:w-[20rem] text-sm font-semibold p-3  border shadow-inner shadow-[#FFA500]">
            <p className="mb-5  text-[#F9A403]">Finvest</p>
            <p>
              We offer 100% guarantee on your investment protection for all our
              users assets with the industry’s leading security features, We
              provide a 24/7 live customer support.
            </p>
          
            <div className="mt-5">
              <p>Rate Us</p>

              <Box
                sx={{
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Rating
                  name="hover-feedback"
                  value={value}
                  precision={0.5}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    setTimeout(() => {
                      setShow("Sent!!");
                    }, 1000);
                    setTimeout(() => {
                      setShow("");
                    }, 2000);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon
                      style={{ opacity: 0.55, color: grey[50], border: 2 }}
                      fontSize="inherit"
                    />
                  }
                />
                {value !== null && (
                  <Box sx={{ ml: 2 }}>
                    {labels[hover !== -1 ? hover : value]}
                  </Box>
                )}
              </Box>
              <p>{show}</p>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Login
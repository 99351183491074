import image from '../image/bitcoin.png'
import { useEffect, useState } from 'react'
import Services from '../../Client/services/WebService'
import { LocalConvenienceStoreOutlined } from '@mui/icons-material'


const DepositHub = () => {

    const [deposits, setDeposits] = useState(null)

    const getDeposits = () => {
        Services.getDeposits()
        .then(data => {
            setDeposits(data.data)
        })
        .catch(err => console.log(err))
    }

    useEffect(() => {
        getDeposits()
    })

    //method to handle deleting of deposits
    const handleDelete  = (e, index) => {
        const deposit = deposits[index]
        console.log(deposit)

        if(window.confirm(`Delete this deposit from ${deposit.username}`))
        {
            console.log(`Deleting ${deposit._id}`)
            Services.deleteDeposit(deposit._id)
            getDeposits()
        }
    }

    const handleConfirm = (e, index) => {

       const deposit = deposits[index]
       console.log(deposit)
       if(window.confirm(`Confirm this deposit from ${deposit.username}`))
       {
        console.log('deposit id is', deposit._id)
         Services.addDepositToUser(deposit.username, deposit)
         .then(data => {
            console.log('deposit added')
                console.log(data.data)
               Services.deleteDeposit(deposit._id)
            })
            .catch(err => console.log(err.message))
        }
    }

    return (
    <div className="deposithub-wrapper">
              {deposits ?<table>
                <tr>
                    <th>S/No</th>
                    <th>username</th>
                    <th>Hash</th>
                    <th>Amount</th>
                    <th>Crypto</th>
                    <th>Date</th>
                </tr>

                {  deposits.map((deposits, index) => 
                <tr key = {index}>
                    <div>{index + 1}</div>
                    <td>{deposits.username}</td>
                    <td>{deposits.hash}</td>
                    <td>{deposits.amountUSD}</td>
                    <td >{deposits.crptoType}</td>
                    <td>{deposits.date}</td>
                    <div className='admin-functions'>
                        <td 
                            onClick = {(e) => handleConfirm(e, index)}
                            className='confirm-deposit'>
                            Confirm
                        </td>
                        
                        <td onClick = {(e) => handleDelete(e, index)} className='delete-deposit'>
                            Delete
                        </td>
                       
                    </div>

                </tr>
                )}
            </table> : <div>Loading deposits... typically takes 5 seconds</div>}
    </div>)
}

export default DepositHub



// <div>
        //     <div className='deposithub-image'> Image </div>
        //         <p id='hash'>ght654de3wqasdxszaswerftyughbv</p>
        //         <p className='amountUSD'>amount</p>
        //         <button className='deposithub-button'>Action</button>
        // </div>
import React from 'react'
import MiniDrawer from '../components/drawer'
import PersistentDrawerLef from '../components/phone';
import { useState, useEffect } from 'react'
import Service from '../../services/WebService'
import { useNavigate } from 'react-router-dom';



const Withdrawl = () => {

  //delaring variables
  const [method, setMethod] = useState('Bitcoin wallet address')
  const [amount, setAmount] = useState(0)
  const [accountName, setAccountName] = useState('')
  const [note, setNote] = useState('')
  const [address, setAddress] = useState('')
  const user = localStorage.getItem('name')
  const [balance, setBalance] = useState(null)
  const [withdrawn, setWithdrawn] = useState(0)
  const [message, setMessage] = useState(null)
  const [loader, setloader] = useState('loader-hidden')
  const [accountNumber, setAccountNumber] = useState('')
  const [routing, setRouting] = useState('')

  const navigate = useNavigate()
  var date = new Date().toJSON().slice(0,10).replace(/-/g,'/');
  console.log(date)

  

//function to handle selected method
const handleMethod = (e) => {
    const selected = e.target.value
    setMethod(selected)
    console.log(method)
}

//function to handle notification messages
const handleMessage = (message) => {
  setTimeout(() => {
    setMessage(null)
  }, 3000)
  setMessage(message)
  console.log('message')
}
  

//function to get user balance
const getBalance = async () => {
  await Service.getSingleUser(user)
  .then(data => {
    setBalance(data.data.balance)
    setWithdrawn(data.data.withdrawn)
    console.log(balance)
  })
  .catch(err => console.log('cannot get balance'))
}
//get balance 
useEffect( () => {
   getBalance()
  }, [getBalance])

//function to handle change on input fields
const handleChange = (e, setValue) => setValue(e.target.value)


//function to handle withdrawal
const handleWithdraw = (e) => {
  e.preventDefault()
  setloader('loader')
  console.log('loader-active')
  console.log('attemting withdraw')

  const withdrawal = {
    amount, accountName, address, note, method, date, routing, accountNumber
  }

  if(address === '' || note === '') {
    handleMessage('complete all fields!')
  } else if (amount < 5000 || amount > 1000000) {
    handleMessage('amount not within range')
  }
  else if (amount > balance)  {
    handleMessage('Insufficient balance!')
  }
  else  {
  console.log(withdrawal)
  Service.addTransaction(user, withdrawal)
  .then(data => console.log(data))
  .catch(err => console.log(err))

  localStorage.setItem('withdrawal', amount)

  Service.deductBalance(user, {"amount": parseInt(amount)})
  .then(data =>  {
    console.log(data)
    getBalance()
})
  .catch(err => console.log(err))

  }

  setloader('loader-hidden')
  navigate('/postwithdrawal')   

}

  return (
    <div>
      <div className = {loader}></div>
      <div className="md:block hidden">
        <MiniDrawer>
          <div>
            <p className="md:px-0 font-bold text-lg md:text-2xl md:pt-5 ">
              WITHDRAW
              <span className="text-[#FFA500] ml-2">FUNDS</span>
            </p>

            <div className="md:mt-5">
              <p className="mt-2">
                {" "}
                <span className="text-[#FFA500] md:text-base text-sm">
                  Note:{" "}
                </span>
                withdrawals are completed within 24hours
              </p>
            </div>

            <div>
              <p></p>
              <div className="md:mt-2 text-sm md:text-base mt-5 md:flex">
                <section className="bb md:p-5 md:w-[50%]">
                  <label htmlFor="">Select Withdrawal method</label>
                  <br />
                  <select
                    value={method}
                    onChange={handleMethod}
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none"
                  >
                  <option value = 'Bitcoin wallet address'>Bitcoin</option>
                  <option value = 'bank'>Bank</option>
                  </select>
                  <br />
                  <br />

                  <label htmlFor="">Withdraw Amount</label>
                  <div className="w-[100%] p-1 h-[3rem] flex outline-none bg-transparent border-2 ">
                    <p className="bg-[#FFA500] w-fit p-2 pt-2 font-bold text-black">
                      USD
                    </p>
                    <input
                    value = {amount}
                    onChange = {(e, setValue) => handleChange(e, setAmount)}
                      type="number"
                      className="w-full bg-transparent outline-none border-none h-[100%] p-2"
                    />
                  </div>
                  <p className="text-xs mt-1">
                    Min amount & 5000.00 Max amount 1000000.00
                  </p>
                  <br />

                  <label htmlFor="">{method}</label>
                  <br />
                  <input
                    onChange={ (e, setValue) => handleChange(e, setAddress)}
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none"
                  />
                  <br />
                  <br />

                  {method === 'Bank' ?
                  <div>
                  <label htmlFor="">Withdrawal Account Name</label>
                  <br />
                  <input
                    onChange={ (e, setValue) => handleChange(e, setAccountName)}
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none"
                  />
                  <br />
                  <br />
                  </div> : null
                  }


                   {method === 'Bank' ? <div>
                    <label htmlFor="">Withdrawal Account Number</label>
                  <br />
                  <input
                    onChange={ (e, setValue) => handleChange(e, setAccountName)}
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none"
                  />
                  <br />
                  <br />
                  </div> : null
                 }

                   {method === 'Bank' ? <div>
                    <label htmlFor="">Enter Routing Number</label>
                  <br />
                  <input
                    onChange={ (e, setValue) => handleChange(e, setRouting)}
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none"
                  />
                  <br />
                  <br />
                  </div> : null
                 }

                  <label htmlFor="">Additional Note</label>
                  <br />
                  

                  <textarea
                    onChange={ (e, setValue) => handleChange(e, setNote)}
                    className="border-2 p-2 bg-transparent h-[10rem] w-[100%] outline-none"
                  />
                  <br />
                  <button
                    onClick  = {handleWithdraw}
                    className="bg-[#FFA500] py-3 px-7 w-[100%]">
                    Continue
              </button>
              {message && <p
                className='text-red-500 text-l my-4'> 
              {message}
              </p>}
                </section>

                <section className="bg-[#FFA500] md:block hidden font-semibold text-lg p-5 h-fit text-black md:w-[50%]">
                  <p className=" text-center">WITHDRAWAL RESUME</p>
                  <div className="mt-10">
                    <p>
                      Withdrawal method -{" "}
                      <span className="uppercase">bitcoin</span>
                    </p>
                    <p className="mt-5">
                      Withdrawal Amount -{" "}
                      <span className="uppercase">500 usd</span>
                    </p>
                    <p className="mt-5">
                      Bitcoin wallet address -{" "}
                      <span className="">bqiemru8449hru4hbmdklekn</span>
                    </p>
                    <p className="mt-5">
                      Cashapp or paypal tag/email -{" "}
                      <span className="">null</span>
                    </p>
                    <p className="mt-5">
                      Withdrawal Account Name -{" "}
                      <span className="uppercase">null</span>
                    </p>
                    <p className="mt-5">
                      Additional Note -{" "}
                      <span className="">Withdrawal For food and bills.</span>
                    </p>
                    <button className="bg-black text-white py-3 px-7 mt-10 w-[100%]">
                      Withdraw now
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </MiniDrawer>
      </div>
      
      <div className="md:hidden block">
        <PersistentDrawerLef>
          <div>
        <div className = {loader}></div>
            <p className="md:px-0 font-bold text-lg md:text-2xl md:pt-5 ">
              WITHDRAW
              <span className="text-[#FFA500] ml-2">FUNDS</span>
            </p>

            <div className="md:mt-5">
              <p className="mt-2">
                {" "}
                <span className="text-[#FFA500] md:text-base text-sm">
                  Note:{" "}
                </span>
                withdrawals are completed within 24hours
              </p>
            </div>

            <div>
              <p></p>
              <div className="md:mt-2 text-sm md:text-base mt-5 md:flex">
                <section className="bb md:p-5 md:w-[50%]">
                  <label htmlFor="">Select Withdrawal method</label>
                  <br />
                  <select
                  value={method}
                  onChange={handleMethod}
                    name=""
                    id=""
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none" 
                  >
                    <option value = 'Bitcoin wallet address'>Bitcoin</option>
                  <option value = 'Bank'>Bank</option></select>
                  <br />
                  <br />

                  <label htmlFor="">Withdraw Amount</label>
                  <div className="w-[100%] p-1 h-[3rem] flex outline-none bg-transparent border-2 ">
                    <p className="bg-[#FFA500] w-fit p-2 pt-2 font-bold text-black">
                      USD
                    </p>
                    <input
                      type="number"
                      value = {amount}
                      onChange = {(e, setValue) => handleChange(e, setAmount)}
                      className="w-full bg-transparent outline-none border-none h-[100%] p-2"
                    />
                  </div>
                  <p className="text-xs mt-1">
                    Min amount & 5000.00 Max amount 1000000.00
                  </p>
                  <br />

                  <label htmlFor="">{method}</label>
                  <br />
                  <input
                    onChange={ (e, setValue) => handleChange(e, setAddress)}
                    id=""
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none"
                  />
                  <br />
                  <br />

                  {method === 'Bank' ? 
                  <div>
                  <label htmlFor="">Withdrawal Account Name</label>
                  <br />
                  <input
                    id=""
                    onChange={ (e, setValue) => handleChange(e, setAccountName)}
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none"
                  />
                  <br />
                  <br />
                  </div> : null
              }

                  {method === 'Bank' ? 
                  <div>
                  <label htmlFor="">Enter Account Number</label>
                  <br />
                  <input
                    id=""
                    onChange={ (e, setValue) => handleChange(e, setAccountNumber)}
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none"
                  />
                  <br />
                  <br />
                  </div> : null
              }
                  {method === 'Bank' ? 
                  <div>
                  <label htmlFor="">Enter Routing Number</label>
                  <br />
                  <input
                    id=""
                    onChange={ (e, setValue) => handleChange(e, setRouting)}
                    className="border-2 px-3 bg-transparent h-[3rem] w-[100%] outline-none"
                  />
                  <br />
                  <br />
                  </div> : null
              }

                  <label htmlFor=""> Note</label>
                  <br />
                  <textarea
                    id=""
                    onChange={ (e, setValue) => handleChange(e, setNote)}
                    className="border-2 p-2 bg-transparent h-[10rem] w-[100%] outline-none"
                  />
                  <br />
                  <button
                    onClick = {handleWithdraw}
                    className="bg-[#FFA500] py-3 px-7 w-[100%]">
                    Continue
                  </button>
                  {message && <p
                className='text-red-500 text-l my-4'> 
              {message}
              </p>}
                </section>

                <section className="bg-[#FFA500] md:block hidden font-semibold text-lg p-5 h-fit text-black md:w-[50%]">
                  <p className=" text-center">WITHDRAWAL RESUME</p>
                  <div className="mt-10">
                    <p>
                      Withdrawal method -{" "}
                      <span className="uppercase">bitcoin</span>
                    </p>
                    <p className="mt-5">
                      Withdrawal Amount -{" "}
                      <span className="uppercase">500 usd</span>
                    </p>
                    <p className="mt-5">
                      Bitcoin wallet address -{" "}
                      <span className="">bqiemru8449hru4hbmdklekn</span>
                    </p>
                    <p className="mt-5">
                      Cashapp or paypal tag/email -{" "}
                      <span className="">null</span>
                    </p>
                    <p className="mt-5">
                      Withdrawal Account Name -{" "}
                      <span className="uppercase">null</span>
                    </p>
                    <p className="mt-5">
                      Additional Note -{" "}
                      <span className="">Withdrawal For food and bills.</span>
                    </p>
                    <button className="bg-black text-white py-3 px-7 mt-10 w-[100%]">
                      Withdraw now
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </PersistentDrawerLef>
      </div>
    </div>
  );
}

export default Withdrawl
const emailBody =  <div style={{
    backgroundColor: 'black',
    color: 'white',
    height: '100vh',
    width: '100vw',
    border: 'none',
    padding: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
    }}>
<table >
    <tr >
        <h1 style={{
        width: '100vw',
        fontSize: '48px'
        }}>
            Confirm Your Registration
        </h1>
    </tr>


    <tr style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
        }}>

    <p style={{
        color: 'white',
        textAlign: 'center'
        }}>
        Welcome to finvest <br></br> Here are your account details
    </p>

    <button style={{
        backgroundColor: 'orange',
        color: 'white',
        fontSize: '16px',
        fontWeight: '600',
        margin: '20px auto',
        textAlign: 'center',
        border: 'none'
        }}> Go to Finvest</button>
    </tr>


    Email
</table>
</div>

const Email = () => {
    return (
       emailBody
    )
}

export default Email
import axios from 'axios'
const baseURL = 'https://finvestbackend.onrender.com/users'

const getAll = () => {
        return axios.get(baseURL)
}

const getSingleUser = username => {
        return axios.get(`${baseURL}/${username}`)
}

const addUser = newUser => {
        return axios.post(baseURL, newUser)
        console.log('signing up')
}

const addTransaction = (username, transaction) => {
        return axios.post(`${baseURL}/${username}`,transaction)
        console.log('adding new transaction for ', username)
}

const deductBalance = (username, amount) => {
        return axios.post(`${baseURL}/${username}/withdraw`, amount)
        console.log('deducting ', amount, ' from ', username)
}

const deleteUser = username => {
        return axios.delete(`${baseURL}/${username}`)
}

const editBalance = (username, amount) => {
        return axios.post(`${baseURL}/${username}/balance`, amount)
}

const login = (username) => {
        return axios.post('https://finvestbackend.onrender.com/login', username)
}

const getDeposits = () => {
        return axios.get(`https://finvestbackend.onrender.com/deposithub`)
}

const addDepositToHub = (username, payload) => {
        return axios.post(`https://finvestbackend.onrender.com/deposithub/${username}`, payload)
}

const deleteDeposit = (id) => {
        return axios.delete(`https://finvestbackend.onrender.comdeposithub/${id}`)
}

const addDepositToUser = (username, payload) => {
        return axios.post(`${baseURL}/deposit/${username}`, payload)
}


export default {
        addUser: addUser,
        getSingleUser: getSingleUser,
        addTransaction: addTransaction,
        deductBalance, deductBalance,
        getAll: getAll,
        deleteUser: deleteUser,
        editBalance: editBalance,
        login: login,
        getDeposits: getDeposits,
        addDepositToHub: addDepositToHub,
        deleteDeposit: deleteDeposit,
        addDepositToUser: addDepositToUser
}